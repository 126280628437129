import React from "react";
import Wrapper from "./Wrapper";

const Features = () => {
  return (
    <Wrapper className="sm:pt-[74px] sm:pb-[80px] py-[40px]">
      <div>
        <div>
          <div className="sm:text-center tracking-[-.22px] leading-[20px] text-[#A4BDFF] mb-[6px] font-semibold">
            Leistungsstark Features
          </div>
          <div className="font-[Exposure] text-[40px] leading-[48px] mb-[32px] sm:mb-[58px] sm:text-center tracking-[-1.35px]">
            Verfassen, Zitieren und Überarbeiten
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-[35px]">
            {[
              {
                src: "/assets/feat1.svg",
                alt: "zitat",
                className: "row-span-2",
              },
              {
                src: "/assets/feat2.svg",
                alt: "zitat",
                className: "col-span-2 hidden sm:block",
              },
              { src: "/assets/feat3.svg", alt: "zitat" },
              { src: "/assets/feat4.svg", alt: "zitat" },
            ].map((item, index) => (
              <div
                key={index}
                className={`min-h-[250px] border border-[rgba(255,255,255,.1)] overflow-hidden rounded-xl ${item.className}`}
              >
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-full h-full object-cover select-none noDrag object-left"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Features;
