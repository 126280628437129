import React from "react";

interface WrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export default function Wrapper({
  children,
  className,
  ...props
}: WrapperProps) {
  return (
    <div
      className={"max-w-[987px] mx-auto max-[1000px]:px-4 " + (className || "")}
      {...props}
    >
      {children}
    </div>
  );
}
