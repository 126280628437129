import "./App.css";
import AIFeature from "./components/AIFeature";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import MobileHeader from "./components/MobileHeader";
import TrustedBy from "./components/TrustedBy";

function App() {
  return (
    <div>
      <Header />
      <MobileHeader />
      <Hero />
      <TrustedBy />
      <Features />
      <AIFeature />
      <Footer />
    </div>
  );
}

export default App;
