import React from "react";
import Wrapper from "./Wrapper";

export default function Header() {
  return (
    <header className="sm:block hidden">
      <Wrapper className="py-[30px] flex items-center justify-between">
        <div className="transition-all duration-300">
          {/* <a
            href="#"
            target="_blank"
            className="mr-[27px] opacity-60 text-[18px] leading-[24px] hover:opacity-100 font-semibold"
          >
            Unsere Daten
          </a> */}
          <a
            href="https://help.noorioo.com/de/articles/9778748-preise"
            target="_blank"
            className="mr-[27px] opacity-60 text-[18px] leading-[24px] hover:opacity-100 font-semibold"
            rel="noreferrer"
          >
            Preise
          </a>
          <a
            href="https://help.noorioo.com/"
            target="_blank"
            className="opacity-60 text-[18px] leading-[24px] hover:opacity-100 font-semibold"
            rel="noreferrer"
          >
            Hilfezentrum
          </a>
        </div>

        <div className="flex items-center transition-all">
          <a
            href="https://app.noorioo.com/"
            target="_blank"
            className="mr-[27px] opacity-60 text-[18px] leading-[24px] hover:opacity-100 font-semibold"
            rel="noreferrer"
          >
            Anmelden
          </a>
          <a
            href="https://app.noorioo.com/"
            target="_blank"
            className="text-[18px] leading-[24px] border-2 border-[rgba(255,255,255,.3)] px-[18px] py-[9px] font-semibold hover:border-[#fff]"
            rel="noreferrer"
          >
            Registrieren
          </a>
        </div>
      </Wrapper>
    </header>
  );
}
