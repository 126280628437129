import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const MobileHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const linkVariants = {
    hover: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="relative hidden max-sm:block">
      <button
        name="menu"
        onClick={toggleMenu}
        className="p-4 text-white hover:text-gray-200 focus:outline-none ml-auto block"
      >
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="13" y="25" width="34" height="3" fill="white" />
          <rect x="13" y="35" width="34" height="3" fill="white" />
        </svg>
      </button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={toggleMenu}
            />
            <motion.div
              ref={menuRef}
              className="fixed z-20 top-0 left-0 h-full w-64 bg-[#262626] text-white p-5"
              initial={{ x: -300 }}
              animate={{ x: 0 }}
              exit={{ x: -300 }}
              transition={{ duration: 0.5 }}
            >
              <div className="py-[30px] flex flex-col items-start justify-between h-full">
                <div className="flex flex-col transition-all duration-300">
                  <NavLinks />
                </div>
                <div className="flex flex-col items-start transition-all">
                  <a
                    href="#"
                    target="_blank"
                    className="mb-[27px] opacity-60 text-[18px] leading-[24px] hover:opacity-100 font-semibold"
                  >
                    Anmelden
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="text-[18px] leading-[24px] border-2 border-[rgba(255,255,255,.3)] px-[18px] py-[9px] font-semibold hover:border-[#fff]"
                  >
                    Registrieren
                  </a>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MobileHeader;

const NavLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="mb-[27px] last:mb-0 opacity-60 text-[18px] leading-[24px] hover:opacity-100 font-semibold transition-opacity duration-300"
  >
    {children}
  </a>
);

export const links = [
  // { href: "#", text: "Unsere Daten" },
  {
    href: "https://help.noorioo.com/de/articles/9778748-preise",
    text: "Preise",
  },
  { href: "https://help.noorioo.com/", text: "Hilfezentrum" },
];

const NavLinks = () => {
  return (
    <div className="flex flex-col animate-fade-in">
      {links.map((link, index) => (
        <NavLink key={index} href={link.href}>
          {link.text}
        </NavLink>
      ))}
    </div>
  );
};
