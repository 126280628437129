import React from "react";
import Wrapper from "./Wrapper";

const Footer: React.FC = () => {
  return (
    <div className="relative z-10 bg-[#212121]">
      <div>
        <div className="border-y border-[rgba(255,255,255,.1)]">
          <Wrapper className="flex gap-4 text-[14px] py-[20px]">
            {[
              {
                name: "Über uns",
                link: "https://help.noorioo.com/de/articles/9779745-uber-uns",
              },
              {
                name: "Instagram",
                link: "https://www.instagram.com/trynoorioo/",
              },
              {
                name: "Tiktok",
                link: "https://www.tiktok.com/@usenoorioo",
              },
              {
                name: "Youtube",
                link: "https://www.youtube.com/@trynoorioo",
              },
              { name: "Noorioo", link: "https://app.noorioo.com/" },
              // { name: "Blog", link: "" },
            ].map((item, index) => (
              <a
                className="hover:!opacity-60"
                target="_blank"
                key={index}
                href={item.link}
                rel="noreferrer"
              >
                {item.name}
              </a>
            ))}
          </Wrapper>
        </div>
        <Wrapper>
          <p className="text-xs !opacity-60 py-4">
            © 2024 Noorioo Pte. Ltd. (201227417H) 16 Raffles Quay, #33-03 Hong
            Leong Building, Singapore 048581
          </p>
        </Wrapper>
      </div>
    </div>
  );
};

export default Footer;
