import React from "react";
import Wrapper from "./Wrapper";

const AIFeature: React.FC = () => {
  return (
    <div className="bg-[#262626]">
      <Wrapper className="pt-[28px] pb-[0px]">
        <div>
          <div>
            <div className="sm:text-center tracking-[-.22px] leading-[20px] text-[#A4BDFF] mb-[6px] font-semibold">
              AI Autocomplete
            </div>
            <div className="font-[Exposure] text-[40px] leading-[48px] mb-[27px] sm:text-center tracking-[-1.35px]">
              Keine Schreibblockaden mehr
            </div>
            <button
              onClick={() => window.open("https://app.noorioo.com/", "_blank")}
              className="bg-[#3A71FF] mb-10 sm:mb-[75px] font-bold text-[18px] sm:text-[20px] w-full leading-[34px] p-[16px] sm:px-[32px] min-h-[50px] rounded-[3px] py-[11px] sm:w-max mx-auto block hover:bg-[#4c7df7]"
            >
              Starte zu schreiben
              <span className="opacity-50"> - kostenlos</span>
            </button>
            <div className="overflow-hidden rounded-t-lg mb-[-10%]">
              <img
                src="/assets/autocomplete.gif"
                alt="ai"
                className="object-cover select-none noDrag relative -inset-px h-[calc(100%+2px)] w-full"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default AIFeature;
