import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import Wrapper from "./Wrapper";
import {
  Goethe,
  KIT,
  Hamburg,
  RWTH,
  Stuttgart,
  TUM,
  LMU,
  BerlinUni,
  BerlinSchool,
  Unims,
  MIT,
  Bern,
} from "../constants/Svgs";

const logos = [
  Goethe,
  Stuttgart,
  Hamburg,
  TUM,
  RWTH,
  KIT,
  LMU,
  BerlinUni,
  BerlinSchool,
  Unims,
  MIT,
  Bern,
  () => <img src="/assets/uniwen.svg" className="w-[100px]" alt="uniwen" />,
  () => <img src="/assets/logo_amsterdam.png" alt="logo_amsterdam" />,
  () => <img src="/assets/uzh_logo.png" alt="uzh_logo" />,
];

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: [0, 1, 1, 0],
    y: [20, 0, 0, -20],
    transition: {
      duration: 4,
      times: [0, 0.1, 0.9, 1],
    },
  },
};

const TrustedBy = () => {
  const containerRef = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(containerRef, { amount: 0, once: true });
  const [index, setIndex] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);

  const cycleIndex = useCallback(() => {
    setIndex((prevIndex) => (prevIndex + 1) % 3);
  }, []);

  useEffect(() => {
    if (isInView && !hasAnimated) {
      controls.start("visible");
      setHasAnimated(true);
    }
  }, [isInView, controls, hasAnimated]);

  const visibleLogos = useMemo(() => {
    return logos.slice(index * 5, index * 5 + 5);
  }, [index]);

  const handleAnimationComplete = useCallback(() => {
    cycleIndex();
    controls.start("visible");
  }, [cycleIndex, controls]);

  return (
    <div className="bg-[#262626] sm:py-10 py-6" ref={containerRef}>
      <Wrapper>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          className="flex flex-col"
        >
          <motion.div className="grid grid-cols-3 sm:flex flex-wrap items-center gap-x-5 sm:gap-x-9 mb-5">
            {visibleLogos.map((Logo: any, i: number) => (
              <motion.div
                key={i}
                variants={itemVariants}
                onAnimationComplete={
                  i === 4 ? handleAnimationComplete : undefined
                }
                className={`sm:w-24 sm:h-24 w-full h-14 flex items-center sm:justify-center ${
                  i === 2 ? "justify-end sm:justify-start" : ""
                }`}
              >
                <Logo />
              </motion.div>
            ))}
          </motion.div>
          <motion.div
            className="text-white text-opacity-60 max-w-2xl sm:text-[16px] text-[14px]"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 0.4 },
              },
            }}
          >
            Noorioo wird von Akademikern
            <br className="sm:hidden block" /> der ganzen Welt vertraut.
          </motion.div>
        </motion.div>
      </Wrapper>
    </div>
  );
};

export default React.memo(TrustedBy);
