import React, { useState, useEffect } from "react";
import Wrapper from "./Wrapper";

const Hero: React.FC = () => {
  const [academicsCount, setAcademicsCount] = useState<number>(0);

  useEffect(() => {
    const getRandomNumber = (min: number, max: number): number => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getDailyData = (): { [key: string]: number } => {
      const storedData = localStorage.getItem("academicsDailyData");
      return storedData ? JSON.parse(storedData) : {};
    };

    const setDailyData = (data: { [key: string]: number }): void => {
      localStorage.setItem("academicsDailyData", JSON.stringify(data));
    };

    const getDateKey = (date: Date): string => {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    };

    const calculateCount = (): number => {
      const now = new Date();
      const dailyData = getDailyData();
      let total = 0;

      for (let i = 0; i < 7; i++) {
        const date = new Date(now);
        date.setDate(date.getDate() - i);
        const dateKey = getDateKey(date);

        if (!dailyData[dateKey]) {
          dailyData[dateKey] = getRandomNumber(300, 500);
        }

        total += dailyData[dateKey];
      }

      // Remove data older than 7 days
      Object.keys(dailyData).forEach(key => {
        const keyDate = new Date(key);
        if ((now.getTime() - keyDate.getTime()) / (1000 * 3600 * 24) >= 7) {
          delete dailyData[key];
        }
      });

      setDailyData(dailyData);

      return total;
    };

    const updateCount = (): void => {
      const newCount = calculateCount();
      setAcademicsCount(newCount);
    };

    updateCount();
    const intervalId = setInterval(updateCount, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Wrapper>
      <div className="sm:mt-[120px] mt-[34px]" />
      <div>
        <div className="font-[Exposure] mb-[24px] text-[11vw] leading-[53px] sm:text-[84px] sm:leading-[100px] sm:mb-[30px]">
          Booste Deine akademische Arbeit
        </div>
        <div className="text-[24px] leading-[35px] opacity-70 mb-[30px] hidden sm:block">
          Verfasse Deine Forschungsarbeiten bis zu 40% schneller mit Noorioos bahnbrechenden Schreib- und Zitiertools. Fokussiere Dich sich auf die Kernaspekte Deiner Forschung.
        </div>
        <div className="flex-col flex sm:flex-row sm:items-center gap-[22px] mb-[47px] sm:mb-[92px]">
          <button
            onClick={() => window.open("https://app.noorioo.com/", "_blank")}
            className="bg-[#3A71FF] w-full sm:w-max font-bold text-[18px] sm:text-[28px] leading-[26px] sm:leading-[34px] sm:px-[32px] px-[16px] text-center min-h-[56px] sm:min-h-[72px] rounded-[3px] py-[15px] sm:py-[18px] hover:bg-[#4c7df7]"
          >
            <p className="hidden sm:block">
              Für Noorioo anmelden{" "}
              <span className="opacity-50">- kostenlos</span>
            </p>
            <p className="sm:hidden">
              Für Noorioo anmelden{" "}
              <span className="opacity-50">- kostenlos</span>
            </p>
          </button>
          <div className="flex gap-4">
            <img
              src="/assets/heart.png"
              alt="hero"
              className="h-[44px] w-[44px] object-contain"
            />
            <p>
              <span className="opacity-100">
                {academicsCount.toLocaleString()}
                {" Akademiker "}
              </span>
              <span className="opacity-60">
                traten
                <br />
                Noorioo in den letzten 7 Tagen bei
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#343231] border-2 border-[rgba(255,255,255,.05)] rounded-[5px] p-[12px] sm:p-[20px]">
        <img
          alt="hero"
          src="/assets/hero.gif"
          className="sm:h-full w-full object-cover object-left-top rounded overflow-hidden"
        />
      </div>
      <div className="sm:mb-[50px] mb-[24px]" />
    </Wrapper>
  );
};

export default Hero;
